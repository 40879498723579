import React, { useState, FC } from 'react';
import { cookieTextData } from '~/src/components/common-components/cookies-notification/cookies-notification.constants';
import { SvgCookies } from '~/src/components/svg-components/cookies-info';
import * as Styled from './cookies-notification.styles';

export const CookiesInfoBlock: FC = () => {
    const [hideCookie, setHideCookie] = useState(false);

    const buttonConfirmHandler = () => {
        localStorage?.setItem(cookieTextData.key, cookieTextData.value);
        setHideCookie(!hideCookie);
    };

    const getPolicyCookie = () => {
        return localStorage?.getItem(cookieTextData.key);
    };

    if (getPolicyCookie()) {
        return null;
    }

    return (
        <Styled.CookieContainer data-testid={'cookie'}>
            <Styled.CookieWrapper>
                <Styled.IconContainer>
                    <SvgCookies />
                </Styled.IconContainer>
                <Styled.ToastContainer>
                    <Styled.ToastText data-testid={'toast-text'}>
                        {cookieTextData.text}
                        &nbsp;
                        <Styled.CookieLink
                            href={cookieTextData.link}
                            target="_blank"
                            rel="noopener, noreferrer"
                            data-testid={'link'}
                        >
                            {cookieTextData.textPolicy}
                        </Styled.CookieLink>
                    </Styled.ToastText>
                </Styled.ToastContainer>
                <Styled.Button onClick={buttonConfirmHandler}>{cookieTextData.buttonConfirm}</Styled.Button>
            </Styled.CookieWrapper>
        </Styled.CookieContainer>
    );
};
