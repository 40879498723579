import { useEffect, useCallback } from 'react';
import TagManager from 'react-gtm-module';

export const GTM_CODE_AGENTS = 'GTM-MPMKN4F';

export const useInitGtm = () => {
    const initialize = useCallback(() => {
        const tagManagerArgs = { gtmId: GTM_CODE_AGENTS };
        TagManager.initialize(tagManagerArgs);
    }, []);

    useEffect(() => {
        initialize();
    }, []);
};