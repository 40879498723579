export class OffersState {
    offerBlobLink: string = '';
    codexBlobLink: string = '';
}

export enum ActionTypesOfferEnum {
    setOfferBlobLink = 'SET_OFFER_BLOB_LINK',
    setCodexBlobLink = 'SET_CODEX_BLOB_LINK',
}

interface SetOfferLinkActionInterface {
    type: typeof ActionTypesOfferEnum.setOfferBlobLink;
    payload: string;
}

interface SetCodexLinkActionInterface {
    type: typeof ActionTypesOfferEnum.setCodexBlobLink;
    payload: string;
}

export type OfferActionsType = SetOfferLinkActionInterface | SetCodexLinkActionInterface;