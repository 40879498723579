import { rem } from '~/src/helpers/rem-converter.helper';

export const shadows = {
    hover: '0 4px 16px rgba(233, 235, 247, 0.5), 0 -4px 16px rgba(233, 235, 247, 0.5);',
    partnersCardHover: '0 4px 24px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.08);',
    header: '0 0 16px rgb(0 0 0 / 8%), 0 4px 16px rgb(0 0 0 / 8%)',
    geolocation: '0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 20px rgba(0, 0, 0, 0.14)',
    paginationControl: '0 4px 16px 0 rgba(0, 0, 0, 0.08), 0 0 16px 0px rgba(0, 0, 0, 0.08)',
    drawer: '0 12px 20px 0 rgba(0, 0, 0, 0.14), 0 4px 24px 0 rgba(0, 0, 0, 0.12)',
    datePicker: `0 ${rem(12)} ${rem(20)} 0 rgba(0, 0, 0, 0.14), 0 4px ${rem(24)} 0 rgba(0, 0, 0, 0.12);`
};


export const nonMtsColors = {
    vk: '#4c6c91',
    ok: '#f68634',
};
