import styled from 'styled-components';
import { FONTS } from '~/src/styles/style-helpers/font-helper';

export const RootLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 100vh;
    ${FONTS.compact.regular.size17};
    overflow: hidden;
`;
