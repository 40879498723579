import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AxiosError } from 'axios';
import { store } from '~/src/redux/store';
import { axiosSetup } from '~/src/interceptors/axios.setup';
import { version } from '~/version';
import { FULL_LOGIN_PATH } from '~/src/params';
import { NO_AUTH_CODE } from '~/src/models/constants/status-codes';
import { QUERY_KEY_TOKEN } from '~/src/repositories/id-token/keys';

export const useInitiateApp = () => {
    axiosSetup();
    const router = useRouter();

    const goToAuth = async (error: unknown, query: unknown) => {
        const _query = query as { queryKey: string[], [key: string]: unknown };
        let _queryKey = '';

        if (Array.isArray(_query.queryKey)) {
            const [firstKey] = _query.queryKey;
            _queryKey = firstKey;
        }

        if (_queryKey === QUERY_KEY_TOKEN) {
            return;
        }

        if (error instanceof AxiosError && error?.response?.status === NO_AUTH_CODE) {
            await router.push({ pathname: FULL_LOGIN_PATH, query: router.query });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`agents app version: %c${version}`, 'color: #263238; font-weight: bold;');
    }, [store]);

    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
        queryCache: new QueryCache({
            onError: (error, query) => goToAuth(error, query),
        }),
        mutationCache: new MutationCache({
            onError: (error, query) => goToAuth(error, query),
        }),
    }));

    return [queryClient];
};
