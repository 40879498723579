import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdTokenState } from '~/src/redux/id-token/id-token.types';

const initialState = new IdTokenState();

export const idTokenSlice = createSlice({
    name: 'idToken',
    initialState,
    reducers: {
        setIdToken: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                idToken: action.payload,
            };
        },
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isAuth: action.payload,
            };
        },
        setIdTokenLifeTime: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                idTokenLifeTime: action.payload,
            };
        },
    }
});

export const {
    setIdToken,
    setIsAuth,
    setIdTokenLifeTime,
} = idTokenSlice.actions;
export const idTokenReducer = idTokenSlice.reducer;
