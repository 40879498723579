import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralAgentState } from '~/src/redux/referral-agent/referral-agent.types';
import { ReferralAgentLinkEnum } from '~/src/models/enums/referral-agent-link.enum';

const initialState = new ReferralAgentState();

const referralAgentSlice = createSlice({
    name: 'referral-agent',
    initialState,
    reducers: {
        setReferralAgentLinkAction: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                referralAgentLink: action.payload,
            };
        },
        setStateReferralAgentLinkAction: (state, action: PayloadAction<ReferralAgentLinkEnum>) => {
            return {
                ...state,
                stateReferralAgentLink: action.payload,
            };
        },
    },
});

export const { setReferralAgentLinkAction, setStateReferralAgentLinkAction } = referralAgentSlice.actions;
export const referralAgentReducer = referralAgentSlice.reducer;
