import { LegalFormEnum } from '~/src/models/enums/legal-form.enum';
import { LeadsReportStateEnum } from '~/src/models/enums/leads-report-status.enum';
import type { SuggestedOrganizationType } from '~/src/components/blocks/clients-request/organization-info/organization-info.types';

export class LeadState {
    selectedOrganizationPartyIdLead: string = '';
    isRequiredAddressField: boolean = false;
    countCheckedProducts: number = 0;
    suggestedOrganization: SuggestedOrganizationType = {};
    legalForm: LegalFormEnum = LegalFormEnum.LEGAL_ENTITY;
    isShowAddressRequiredPlank: boolean = false;
    createdLeadId: number = 0;
    leadsReportState: LeadsReportStateEnum = LeadsReportStateEnum.DEFAULT;
    leadsReportStatusProgress: boolean = false;
}
