import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RedirectLinkEnum } from '~/src/models/enums/redirect-link.enum';
import { AppState } from '~/src/redux/app/app.types';

const initialState = new AppState();

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setRedirectLinkAction: (state, action: PayloadAction<RedirectLinkEnum>) => {
            return {
                ...state,
                redirectLink: action.payload,
            };
        },
    },
});

export const { setRedirectLinkAction } = appSlice.actions;
export const appReducer = appSlice.reducer;
