import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeadState } from '~/src/redux/lead/lead.types';
import { revertAllLeadData } from '~/src/redux/actions';
import type { SuggestedOrganizationType } from '~/src/components/blocks/clients-request/organization-info/organization-info.types';
import type { LegalFormEnum } from '~/src/models/enums/legal-form.enum';
import type { LeadsReportStateEnum } from '~/src/models/enums/leads-report-status.enum';

const initialState = new LeadState();

const leadSlice = createSlice({
    name: 'lead',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAllLeadData, () => initialState),
    reducers: {
        setSelectedOrganizationPartyIdLead: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                selectedOrganizationPartyIdLead: action.payload,
            };
        },
        setIsRequiredAddressField: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isRequiredAddressField: action.payload,
            };
        },
        setCountCheckedProducts: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                countCheckedProducts: state.countCheckedProducts + action.payload,
            };
        },
        setDispatchSuggestedOrganization: (state, action: PayloadAction<SuggestedOrganizationType>) => {
            return {
                ...state,
                suggestedOrganization: action.payload,
            };
        },
        setLegalForm: (state, action: PayloadAction<LegalFormEnum>) => {
            return {
                ...state,
                legalForm: action.payload,
            };
        },
        setIsShowAddressRequiredPlank: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isShowAddressRequiredPlank: action.payload,
            };
        },
        setCreatedLeadId: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                createdLeadId: action.payload,
            };
        },
        setLeadsReportState: (state, action: PayloadAction<LeadsReportStateEnum>) => {
            return {
                ...state,
                leadsReportState: action.payload,
            };
        },
        setLeadsReportStatusProgress: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                leadsReportStatusProgress: action.payload,
            };
        },
    },
});

export const {
    setSelectedOrganizationPartyIdLead,
    setIsRequiredAddressField,
    setCountCheckedProducts,
    setDispatchSuggestedOrganization,
    setLegalForm,
    setIsShowAddressRequiredPlank,
    setCreatedLeadId,
    setLeadsReportState,
    setLeadsReportStatusProgress,
} = leadSlice.actions;
export const leadReducer = leadSlice.reducer;
