import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralLeadState } from '~/src/redux/referral-lead/referral-lead.types';
import { revertAllLeadReferralData } from '~/src/redux/actions';
import type { SuggestedOrganizationType } from '~/src/components/blocks/clients-request/organization-info/organization-info.types';

const initialState = new ReferralLeadState();

const referralLeadSlice = createSlice({
    name: 'referral-lead',
    initialState,
    extraReducers: (builder) =>
        builder.addCase(revertAllLeadReferralData, (state) => {
            return { ...state, suggestedOrganization: initialState.suggestedOrganization };
        }),
    reducers: {
        setDispatchSuggestedOrganization: (state, action: PayloadAction<SuggestedOrganizationType>) => {
            return {
                ...state,
                suggestedOrganization: action.payload,
            };
        },
        setReferralCode: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                referralCode: action.payload,
            };
        },
        setReferralProductsUrl: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                referralProductsUrl: action.payload,
            };
        },
        setDomainUrl: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                domainUrl: action.payload,
            };
        },
        setCurrentNameTariff: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                currentNameTariff: action.payload,
            };
        },
    },
});

export const {
    setDispatchSuggestedOrganization,
    setReferralCode,
    setReferralProductsUrl,
    setDomainUrl,
    setCurrentNameTariff,
} = referralLeadSlice.actions;
export const referralLeadReducer = referralLeadSlice.reducer;
