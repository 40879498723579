import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeolocationState } from '~/src/redux/geolocation/geolocation.types';
import type { RegionInterface } from '~/src/models/types/mts-bank-product.types';

const initialState = new GeolocationState();

const geolocationSlice = createSlice({
    name: 'geolocation',
    initialState,
    reducers: {
        setCurrentRegion: (state, action: PayloadAction<RegionInterface>) => {
            return {
                ...state,
                currentRegion: action.payload,
            };
        },
        setIsShowGeolocationBlock: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isShowGeolocationBlock: action.payload,
            };
        },
    },
});

export const { setCurrentRegion, setIsShowGeolocationBlock } = geolocationSlice.actions;
export const geolocationReducer = geolocationSlice.reducer;
