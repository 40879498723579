import type {
    SuggestedOrganizationType,
} from '~/src/components/blocks/clients-request/organization-info/organization-info.types';

export class ReferralLeadState {
    referralProductsUrl: string = '';
    referralCode: string = '';
    suggestedOrganization: SuggestedOrganizationType = {};
    domainUrl: boolean = false;
    currentNameTariff: string = '';
}
