import type { OrganizationInterface } from '~/src/repositories/organizations/types/organizations.types';
import type { StatusPrivilegesInterface } from '~/src/repositories/user/user.repository';

export class UserState {
    userProfile: UserProfile = new UserProfile();
    organizations: OrganizationInterface[] = [];
    organizationsIsLoading: boolean = false;
    userPartyId: string = '';
    workingPartyId: string = '';
    isAuthFinished: boolean = false;
    privilegesData: StatusPrivilegesInterface[] = [];
    agentId: string = '';
}

export class UserProfile {
    id: string | null = null;
    name: string = '';
    abonent: number | null = null;
    numberType: string | null = null;
    accountType: string | null = null;
    currentTariff: string | null = null;
    phone: number | string | null = null;
    picture: string = '';
    email: string = '';
    isUpdating: boolean = false;
}