import React, { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalResetStyleLoader = createGlobalStyle`
    html {
        background-image: none !important;
    }
`;

export const usePageResetStyleLoader = () => {
    const [pageResetLoader, setPageResetLoader] = useState(<></>);

    useEffect(() => {
        setPageResetLoader(<GlobalResetStyleLoader/>);
    }, []);

    return [pageResetLoader];
};