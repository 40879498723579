import { combineReducers } from '@reduxjs/toolkit';
import { userReducer } from '~/src/redux/user/user.slice';
import { leadReducer } from '~/src/redux/lead/lead.slice';
import { idTokenReducer } from '~/src/redux/id-token/id-token.slice';
import { documentReducer } from '~/src/redux/documents/document.slice';
import { popupsReducer } from '~/src/redux/popups/popups.slice';
import { referralLeadReducer } from '~/src/redux/referral-lead/referral-lead.slice';
import { offersReducer } from '~/src/redux/offers/offers.slice';
import { referralAgentReducer } from '~/src/redux/referral-agent/referral-agent.slice';
import { appReducer } from '~/src/redux/app/app.slice';
import { geolocationReducer } from '~/src/redux/geolocation/geolocation.slice';
import type { StateModel } from '~/src/models/state.model';

export default combineReducers<StateModel>({
    user: userReducer,
    lead: leadReducer,
    idToken: idTokenReducer,
    documents: documentReducer,
    popups: popupsReducer,
    referralLead: referralLeadReducer,
    offers: offersReducer,
    referralAgent: referralAgentReducer,
    app: appReducer,
    geolocation: geolocationReducer,
});
