export enum BORDER_RADIUS {
    small4 = '0.25rem',
    medium6 = '0.375rem',
    normal8 = '0.5rem',
    bigger12 = '0.75rem',
    big16 = '1rem',
    biggest20 = '1.25rem',
    biggest40 = '2.5rem',
    doubleBig32 = '2rem',
    round = '50%',
    onePx = '1px',
    zero = '0',
}
