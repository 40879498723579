import { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';

type stylesType = FlattenSimpleInterpolation;

interface MinParamRequiredInterface {
    minWidth: number;
    maxWidth?: number;
}

interface MaxParamRequiredInterface {
    minWidth?: number;
    maxWidth: number;
}

type mediaQueryType = MinParamRequiredInterface | MaxParamRequiredInterface;

export const mediaQuery = ({ minWidth, maxWidth }: mediaQueryType, styles: stylesType) => {
    if (minWidth && maxWidth) {
        return css`
            @media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px) {
                ${styles};
            }
        `;
    }

    if (minWidth) {
        return css`
            @media (min-width: ${minWidth}px) {
                ${styles};
            }
        `;
    }

    if (maxWidth) {
        return css`
            @media (max-width: ${maxWidth}px) {
                ${styles};
            }
        `;
    }
};
