import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OffersState } from '~/src/redux/offers/offers.types';

const initialState = new OffersState();

const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        setOfferBlobLinkAction: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                offerBlobLink: action.payload,
            };
        },
        setCodexBlobLinkAction: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                codexBlobLink: action.payload,
            };
        },
    },
});

export const { setOfferBlobLinkAction, setCodexBlobLinkAction } = offersSlice.actions;
export const offersReducer = offersSlice.reducer;
