import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PopupsState } from '~/src/redux/popups/popups.types';
import { revertAllLeadData, revertAllLeadReferralData } from '~/src/redux/actions';
import type { ReactElement } from 'react';

const initialState = new PopupsState();

const popupsSlice = createSlice({
    name: 'popups',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(revertAllLeadData, () => initialState);
        builder.addCase(revertAllLeadReferralData, () => initialState);
    },
    reducers: {
        setPopupContentAction: (state, action: PayloadAction<{ content: ReactElement | null, replace?: boolean }>) => {
            const _replace = action.payload?.replace ?? true;
            const _content = action.payload.content;
            const _payload = { replace: _replace, content: _content };
            const stack = state.popupStack.length ? [...state.popupStack] : [];

            const add = () => {
                action.payload.content ? stack.push(_payload) : stack.pop();
                return stack;
            };

            const stackCopy = [...stack];
            const condition = Boolean(
                _replace && stack.length > 0 && action.payload.content,
            );

            stackCopy.length && stackCopy.pop();
            action.payload.content && stackCopy.push(_payload);

            return {
                ...state,
                popupStack: condition ? stackCopy : add(),
            };
        },
        resetPopupContentAction: (state) => {
            return {
                ...state,
                popupStack: [],
            };
        },
    }
});

export const {
    setPopupContentAction,
    resetPopupContentAction,
} = popupsSlice.actions;
export const popupsReducer = popupsSlice.reducer;
