import type { TypeFilesEnum } from '~/src/models/enums/type-files.enum';

export interface AddDocumentsPayloadInterface<T> {
    blobUrl: string;
    name: string;
    type: string;
    size: number;
    typeField: T;
}

export interface FileDataInterface {
    createdAt: string;
    downloadLink: string;
    id: string;
    name: string;
    showLink: string;
    size: string;
}

export interface SetFileUploadPayloadInterface {
    isSuccess: boolean;
    typeField: TypeFilesEnum;
}

export interface DeleteFilePayloadInterface {
    name: string;
    typeField: TypeFilesEnum;
}

export interface ResetFilePayloadInterface {
    typeField: TypeFilesEnum;
}

export interface AddSizeForFieldPayloadInterface {
    size: number;
    typeField: TypeFilesEnum;
}

export interface SubtractSizeForFieldPayloadInterface {
    size: number;
    typeField: TypeFilesEnum;
}

export interface SetFileDataPayloadInterface {
    data: FileDataInterface;
    typeField: TypeFilesEnum;
}

export interface DocumentInterface {
    files: AddDocumentsPayloadInterface<TypeFilesEnum>[] | [],
    data: FileDataInterface[],
    isSuccess: boolean,
    sizeAll: number,
}

export const initDocumentState: DocumentInterface = { files: [], data: [], isSuccess: false, sizeAll: 0 };

export class DocumentsState {
    additionLead = initDocumentState;
}