import React, { FC } from 'react';
import * as Styled from './service-notice.styles';

interface ServiceNoticeProps {
    textNotice?: string;
    withCustomHeader?: boolean;
}

export const ServiceNotice: FC<ServiceNoticeProps> = ({ textNotice, withCustomHeader }) => {
    if (!textNotice) {
        return null;
    }

    return (
        <Styled.ServiceNotice data-testid={'notice'} role={'alert'} withCustomHeader={withCustomHeader}>
            <div data-testid={'text-notice-wrap'}>{textNotice}</div>
        </Styled.ServiceNotice>
    );
};
