// URL BE
export const API_HOST_ENV = process.env.NEXT_PUBLIC_BASE_URL;

export const API_HOST_CMS_ENV = process.env.NEXT_PUBLIC_URL_CMS;

export const DEVELOPERS_HOST_ENV = process.env.NEXT_PUBLIC_URL_DEVELOPERS;

export const PROFILE_HOST_ENV = process.env.NEXT_PUBLIC_PROFILE;

// Используется для ссылок и URL запросов
export const CURRENT_HOST_ENV = process.env.NEXT_PUBLIC_URL_CURRENT;

export const LOGOUT_ENV = process.env.NEXT_PUBLIC_LOGOUT;

// URL хоста (т.е условно "/")
export const HOST = process.env.NEXT_PUBLIC_HOST;

export const PART_LOGIN_PATH = '/oauth2/authorization/mts-web-sso';

export const SER_CON_AGENTS_URL = '/serviceconnection/agents';

export const CLIENTS_REQUEST = '/clients-request';

export const ABOUT_PRODUCTS = '/about-products';

export const FULL_LOGIN_PATH = API_HOST_ENV + PART_LOGIN_PATH;

export const isProduction = process.env.NEXT_PUBLIC_STAND === 'prod';

export const isSandbox = process.env.NEXT_PUBLIC_STAND === 'sandbox';

export const s3StorageUrl = 'https://agents.mts.ru/static';

export const isAlphaBetaStands = process.env.NEXT_PUBLIC_STAND === 'alpha' || process.env.NEXT_PUBLIC_STAND === 'beta';

export const isAllLocalStands = ['agents', 'alpha', 'beta'].includes(process.env.NEXT_PUBLIC_STAND || '');

export const isAgentsLocalStand = ['agents'].includes(process.env.NEXT_PUBLIC_STAND || '');