import React, { useState, useEffect, ReactPortal, ReactElement } from 'react';
import type { ChildrenType } from '~/src/models/types/common.types';

type NoSSRReturnType = JSX.Element | ReactElement | ReactPortal;
type NoSSRType = ({ children } : { children: ChildrenType }) => NoSSRReturnType;

export const NoSSR: NoSSRType = ({ children }) => {
    const [isBrowser, setIsBrowser] = useState(false);

    useEffect(() => {
        setIsBrowser(true);
    }, []);

    return isBrowser ? <>{children}</> : <></>;
};
