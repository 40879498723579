import * as React from 'react';
import { SVGProps } from 'react';

export const SvgCookies = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.686 1.686C.373 2.999.276 4.306.081 6.923.031 7.6 0 8.3 0 9s.03 1.399.081 2.077c.195 2.616.292 3.924 1.605 5.237 1.313 1.313 2.62 1.41 5.237 1.605C7.6 17.969 8.3 18 9 18s1.399-.03 2.077-.081c2.616-.195 3.924-.292 5.237-1.605 1.313-1.313 1.41-2.62 1.605-5.237C17.969 10.4 18 9.7 18 9s-.03-1.399-.081-2.077c-.195-2.616-.292-3.924-1.605-5.237C15.001.373 13.694.276 11.077.081A28.107 28.107 0 0 0 9 0c-.7 0-1.399.03-2.077.081-2.616.195-3.924.292-5.237 1.605ZM10 4.999a1.249 1.249 0 1 1-2.498 0 1.249 1.249 0 0 1 2.498 0Zm-.293 3.294A1 1 0 0 1 10 9v4a1 1 0 1 1-2 0v-3h-.5a1 1 0 0 1 0-2H9a1 1 0 0 1 .707.293Z"
            fill="#007CFF"
        />
    </svg>
);
