import { FAMILY, SIZE, WEIGHT } from '~/src/styles/tokens/fonts';

type FontType = {
    [key in keyof typeof FAMILY]: {
        [key in keyof typeof WEIGHT]: {
            [key in keyof typeof SIZE]: string;
        };
    };
};

const getFontsObject = (): FontType => {
    const array = Object.entries(FAMILY).map(([family, familyValue]) => {
        return {
            [family]: Object.keys(WEIGHT).map((weight) => ({
                [weight]: Object.entries(SIZE).map(([size, sizeValue]) => ({
                    [size]: `font: ${sizeValue} var(${familyValue}-${weight}), Arial, sans-serif`,
                })),
            })),
        };
    });

    return <FontType>Object.fromEntries(
        array.map((elem) => {
            const [fontFamily] = Object.keys(elem);
            const [weightsArray] = Object.values(Object.values(elem));

            const weightsObj = Object.fromEntries(
                weightsArray.map((sizes) => {
                    const [weight] = Object.keys(sizes);
                    const [sizesArray] = Object.values(sizes);

                    const sizesObj = Object.fromEntries(
                        sizesArray.map((size) => {
                            const [sizeKey] = Object.keys(size);
                            const [attrValue] = Object.values(size);
                            return [sizeKey, attrValue];
                        }),
                    );
                    return [weight, sizesObj];
                }),
            );
            return [fontFamily, weightsObj];
        }),
    );
};

export const FONTS = getFontsObject();
