
require("./../../node_modules/.pnpm/@module-federation+nextjs-mf@6.5.1_next@13.3.4_react-dom@18.2.0_react@18.2.0_webpack@5.87.0/node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import Head from 'next/head';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '@developers/mts-editor-dist/dist/style.css';
import { store } from '~/src/redux/store';
import { NoSSR } from '~/src/helpers/noSSR.helper';
import { useInitiateApp } from '~/src/hooks/use-initiate-app.hook';
import { useInitGtm } from '~/src/hooks/use-init-gtm.hook';
import { usePageResetStyleLoader } from '~/src/hooks/use-page-reset-loader.hook';
import { CookiesInfoBlock } from '~/src/components/common-components/cookies-notification/cookies-notification';
import { ServiceNotice } from '~/src/components/sections/service-notice/service-notice';
import { GlobalStyles } from '~/public/styled-global';
import { RootLayout } from '~/src/styles/shared-css/root-layout';

interface PageProps {
    hideHeader?: boolean;
    hideFooter?: boolean;
    textNotice?: string;
    pageTitle?: string;
    withCustomHeader?: boolean;
}

const HeaderDynamic = dynamic(() => import('~/src/components/sections/header/header'));
const FooterDynamic = dynamic(() => import('~/src/components/sections/footer/footer'));

const Application: FC<AppProps<PageProps>> = (props) => {
    const { Component, pageProps } = props;
    const [queryClient] = useInitiateApp();
    const [pageResetLoaderStyle] = usePageResetStyleLoader();
    useInitGtm();

    return (
        <>
            <Head>
                <title>{pageProps?.pageTitle || 'Партнёрская программа B2B МТС'}</title>
            </Head>
            <GlobalStyles />
            {pageResetLoaderStyle}
            <Provider store={store}>
                <RootLayout>
                    <QueryClientProvider client={queryClient}>
                        {!pageProps.hideHeader && <HeaderDynamic />}
                        <ServiceNotice
                            textNotice={pageProps.textNotice}
                            withCustomHeader={pageProps.withCustomHeader}
                        />
                        <Component {...pageProps} className="main-component-content" />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                    {!pageProps.hideFooter && <FooterDynamic />}
                </RootLayout>
                <NoSSR>
                    <CookiesInfoBlock />
                </NoSSR>
            </Provider>
        </>
    );
};

export default Application;

// @TODO SEO & Metadata (https://beta.nextjs.org/docs/guides/seo#usage)
// @TODO app directly (layouts etc)
// @TODO Sonar

// @TODO typescript v5
// @TODO source map Sentry
