import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { revertAllLeadData } from '~/src/redux/actions';
import { initDocumentState, DocumentsState } from '~/src/redux/documents/documents.types';
import type {
    AddDocumentsPayloadInterface,
    AddSizeForFieldPayloadInterface,
    DeleteFilePayloadInterface,
    ResetFilePayloadInterface,
    SetFileUploadPayloadInterface,
    SetFileDataPayloadInterface,
    SubtractSizeForFieldPayloadInterface,
} from '~/src/redux/documents/documents.types';
import type { TypeFilesEnum } from '~/src/models/enums/type-files.enum';

const initialState = new DocumentsState();

const documentSlice = createSlice({
    name: 'documents',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAllLeadData, () => initialState),
    reducers: {
        addDocuments: (state, action: PayloadAction<AddDocumentsPayloadInterface<TypeFilesEnum>>) => {
            return {
                ...state,
                [action.payload.typeField]: {
                    ...state[action.payload.typeField],
                    files: [...state[action.payload.typeField].files, { ...action.payload }],
                },
            };
        },
        setFileUploadStatus: (state, action: PayloadAction<SetFileUploadPayloadInterface>) => {
            return {
                ...state,
                [action.payload.typeField]: {
                    ...state[action.payload.typeField],
                    isSuccess: action.payload.isSuccess,
                },
            };
        },
        setFileUploadData: (state, action: PayloadAction<SetFileDataPayloadInterface>) => {
            return {
                ...state,
                [action.payload.typeField]: {
                    ...state[action.payload.typeField],
                    data: [...state[action.payload.typeField].data, action.payload.data],
                },
            };
        },
        resetDocument: (state, action: PayloadAction<ResetFilePayloadInterface>) => {
            return {
                ...state,
                [action.payload.typeField]: {
                    ...state[action.payload.typeField],
                    ...initDocumentState,
                },
            };
        },
        deleteDocument: (state, action: PayloadAction<DeleteFilePayloadInterface>) => {
            return {
                ...state,
                [action.payload.typeField]: {
                    ...state[action.payload.typeField],
                    files: state[action.payload.typeField].files.filter((item) => item.name !== action.payload.name),
                    data: state[action.payload.typeField].data.filter((item) => item.name !== action.payload.name),
                },
            };
        },
        addSizeForField: (state, action: PayloadAction<AddSizeForFieldPayloadInterface>) => {
            return {
                ...state,
                [action.payload.typeField]: {
                    ...state[action.payload.typeField],
                    sizeAll: state[action.payload.typeField].sizeAll + action.payload.size,
                },
            };
        },
        subtractSizeForField: (state, action: PayloadAction<SubtractSizeForFieldPayloadInterface>) => {
            return {
                ...state,
                [action.payload.typeField]: {
                    ...state[action.payload.typeField],
                    sizeAll: state[action.payload.typeField].sizeAll - action.payload.size,
                },
            };
        },
    }
});

export const {
    addDocuments,
    setFileUploadStatus,
    setFileUploadData,
    resetDocument,
    deleteDocument,
    addSizeForField,
    subtractSizeForField,
} = documentSlice.actions;
export const documentReducer = documentSlice.reducer;
