import axios from 'axios';
import { store } from '~/src/redux/store';

export const axiosSetup = () => {
    axios.defaults.withCredentials = true;
};

axios.interceptors.request.use((config) => {
    const { idToken: { idToken } } = store.getState();
    config.headers.Authorization = `Bearer ${idToken}`;

    return config;
});