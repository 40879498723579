import type { ReactElement } from 'react';

export interface PopupInterface {
	content: ReactElement | null;
	replace: boolean;
}

export class PopupsState {
    popupStack: PopupInterface[] = [];
}
