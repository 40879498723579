import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { MAX_567, MAX_767 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { shadows } from '~/src/styles/variables';

export const CookieContainer = styled.div`
    position: fixed;
    left: 50%;
    bottom: ${rem(24)};
    z-index: ${Z_INDEX.top};
    transform: translateX(-50%);
    background-color: ${COLORS.textLightPrimary};
    border-radius: ${BORDER_RADIUS.normal8};
    box-shadow: ${shadows.geolocation};

    animation: cookie 1s linear;

    @keyframes cookie {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    ${mediaQuery({ maxWidth: MAX_767 }, css`
        width: ${rem(519)};
    `)};

    ${mediaQuery({ maxWidth: MAX_567 }, css`
        max-width: calc(100% - ${rem(24)});
    `)};
`;

export const CookieWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ToastContainer = styled.div`
    margin: ${rem(10)} 0;
    align-self: center;
`;

export const ToastText = styled.span`
    display: block;
    padding: 0 ${rem(12)} 0 ${rem(13)};
    color: ${COLORS.textInverted};
    border-right: 1px ${COLORS.backgroundStroke} solid;
    ${FONTS.sans.regular.size17};
`;

export const IconContainer = styled.div`
    align-self: center;
    padding: ${rem(13)} 0 ${rem(13)} ${rem(17)};
`;

export const Button = styled.button`
    padding: ${rem(7)} ${rem(21)};
    margin: 3px;
    align-self: center;
    border: 0;
    color: ${COLORS.textInverted};
    background-color: ${COLORS.textLightPrimary};
    ${FONTS.sans.regular.size17};

    &:hover {
        cursor: pointer;
    }
`;

export const CookieLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: ${COLORS.constantLightBlueberry};
`;
