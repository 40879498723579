export const MAX_1919 = 1919;
export const MAX_1679 = 1679;
export const MAX_1439 = 1439;
export const MAX_1365 = 1365;
export const MAX_1279 = 1279;
export const MAX_1023 = 1023;
export const MAX_767 = 767;
export const MAX_567 = 567;
export const MAX_479 = 479;
export const MAX_319 = 319;

export const MIN_1920 = 1920;
export const MIN_1680 = 1680;
export const MIN_1440 = 1440;
export const MIN_1366 = 1366;
export const MIN_1280 = 1280;
export const MIN_1024 = 1024;
export const MIN_768 = 768;
export const MIN_568 = 568;
export const MIN_480 = 480;
export const MIN_320 = 320;
