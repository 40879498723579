import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile, UserState } from '~/src/redux/user/user.types';
import type { OrganizationInterface } from '~/src/repositories/organizations/types/organizations.types';
import type { StatusPrivilegesInterface } from '~/src/repositories/user/user.repository';

const initialState = new UserState();

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUserAction: (state, action: PayloadAction<UserProfile>) => {
            return {
                ...state,
                userProfile: action.payload,
            };
        },
        setOrganizationsAction: (state, action: PayloadAction<OrganizationInterface[] | []>) => {
            return {
                ...state,
                organizations: action.payload,
            };
        },
        setOrganizationsLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                organizationsIsLoading: action.payload,
            };
        },
        setWorkingPartyId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                workingPartyId: action.payload || '',
            };
        },
        setUserPartyId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                userPartyId: action.payload || '',
            };
        },
        setUserProfileIsUpdating: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    isUpdating: action.payload,
                }
            };
        },
        setAuthStateCompletedAction: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isAuthFinished: action.payload,
            };
        },
        setPrivilegesData: (state, action: PayloadAction<StatusPrivilegesInterface[]>) => {
            return {
                ...state,
                privilegesData: action.payload,
            };
        },
        setAgentIdAction: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                agentId: action.payload,
            };
        },
    }
});

export const {
    setCurrentUserAction,
    setOrganizationsAction,
    setOrganizationsLoading,
    setWorkingPartyId,
    setUserPartyId,
    setAuthStateCompletedAction,
    setPrivilegesData,
    setAgentIdAction,
    setUserProfileIsUpdating,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
