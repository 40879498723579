export enum Z_INDEX {
    auto = 'auto',
    top = 99_999,
    level5 = 5,
    level4 = 4,
    level3 = 3,
    level2 = 2,
    level1 = 1,
    level0 = 0,
    bottom = -1,
    under = -10,
}