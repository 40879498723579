import styled from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

export const ServiceNotice = styled.div<{ withCustomHeader?: boolean; }>`
    width: 100%;
    min-height: ${rem(24)};
    display: flex;
    padding: ${rem(12)} ${rem(10)};
    background-color: ${COLORS.accentNegative};
    justify-content: center;
    align-items: center;
    color: ${COLORS.textInverted};
    ${FONTS.sans.regular.size17};
    position: relative;
    z-index: ${Z_INDEX.level1};
    margin-top: ${p => p.withCustomHeader ? rem(52) : 0};

    > div {
        text-align: center;
    }
`;
